@media (min-width: 601px) {
  body {
    background: rgb(245, 245, 245) !important;
  }
}

.accordion_item {
  margin: 15px 20px 15px 20px;
  /* border: 1px solid black; */
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
